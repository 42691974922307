.plans-container{
    margin-top: -4rem;
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.plans{
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
}

.plan{
    display: flex;
    flex-direction: column;
     background-color: var(--caloryCard);
     color: white;
     gap: 2rem;
     padding: 1.5rem;
     width: 15rem;
}

.plans>:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}

.plan>svg{
    width: 2rem;
    height: 2rem;
    fill: var(--orange);
}

.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.plan>:nth-child(5){
    font-size: 0.8rem;
}

.features{
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.feature{
    display: flex;
    gap:1rem;
    align-items: center;
   
}

.feature>img{
    width: 1rem;
}

.plans>:nth-child(2)>svg{
    fill: white;
}

.plans>:nth-child(2)>button{
    color:orange;
}

.plans-blur-1{
    width: 23rem;
    height: 32rem;
    top: 6rem;
    left: 0;
}

.plans-blur-2{
    width: 23rem;
    height: 32rem;
    top: 10rem;
    right: 0;
}

@media screen and (max-width: 768px) {
    .plans{
        flex-direction: column;
    }    
    .plans>:nth-child(2){
        transform: none;
    }
}