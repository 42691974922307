.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0.2rem;
}

.programs-header{
    display: flex;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.Categories{
    display:flex;
    gap: 1rem;
    padding: 12px 20px;
}

.category{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    justify-content: space-between;
    background-color: gray;
    color: white;
}

.category>:nth-child(1){
    height: 2rem;
    width: 2rem;
    fill: white;
}

.category>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
}

.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.join-now>img{
    width: 1rem;
}

.category:hover{
    background: var( --planCard);
    cursor: pointer;
}

@media  screen and (max-width: 758px) {
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        font-size: x-large;
        margin-top: 2rem;
    }
    .Categories{
        flex-direction: column;
    }
}