.header{
    display: flex;
    justify-content: space-between;
    
}
.logo{
    height: 3rem;
    width: 10rem;
}
.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}
.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);

}

@media screen and (max-width: 768px){
   .header>:nth-child(2){
    position: sticky;
    right: 2rem;
    z-index: 99;
    flex-direction: column;
    background: var(--appColor);
    padding: 2rem;
   }
   .header-menu{
    position: fixed;
    right: 2rem;
    z-index: 99;
    flex-direction: column;
    background: var(--appColor);
    padding: 2rem;
   }
}
